<template>
  <div>
    <!-- Modal Attachments-->
    <b-modal
      id="modal-attachments"
      v-model="attachmentModal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Contracts"
    >
        <div>
          <div class="messages">
            <b-form-group label="Agreement Reference *" label-for="agreementRef">
              <b-form-input v-model="agreementRef" placeholder="Agreement Reference" readonly />
            </b-form-group>
             <!-- Customer -->
          <b-form-group label="Customer *" label-for="customer">
            <b-form-input v-model="customer" placeholder="Customer" readonly />
          </b-form-group>

          <!-- Description -->
          <b-form-group label="Description *" label-for="description">
            <b-form-input v-model="description" placeholder="Description" readonly />
          </b-form-group>

          <!-- Start Date -->
          <b-form-group label="Start Date *" label-for="startDate">
            <b-form-input v-model="startDate_" placeholder="Start Date" readonly />
          </b-form-group>

          <!-- End Date -->
          <b-form-group label="End Date *" label-for="endDate">
            <b-form-input v-model="endDate_" placeholder="End Date" readonly />
          </b-form-group>

          <b-form-group label="Attachments *" label-for="fileAttachments">
            <ul
              :ref="fileAttachments"
              v-if="fileAttachments.length > 0"
              class="list-group"
            >
              <li
                v-for="item in fileAttachments"
                :key="item"
                class="list-group-item"
              >
              <div class="float-left">
                <a href="#" @click.prevent="downloadFile(item.url)">{{ item.filename }}</a>
      </div>
              </li>
            </ul>
            </b-form-group>
            <div v-if="fileAttachments.length == 0">
              No Data
            </div><!-- File Upload Section -->
          </div>
        </div>
    </b-modal>
    <!-- Filters -->
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Date</label><br>
          <date-range-picker
            v-model="pickerDates"
            :config="config"
            :locale-data="locale"
            @update="dateChange"
    >
    </date-range-picker>
    </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
          v-if="userRole!=='branch'"
        >
          <label>Branch</label><br>
          <v-select
                v-model="branchId"
                :dir="'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="branchId"
              />
    </b-col>
      </b-row>
    </b-card-body>
  </b-card>
<div>
    <!-- Modal Detail-->
    <b-modal
      id="modal-detail"
      v-model="modalDetailShow"
      centered
      hide-footer
      title="Detail Invoice"
    >
        <b-form-group>
          <label for="email">Reference :</label>
          <input type="text" name="reference" class="form-control" v-model="InvoiceReference" readonly>
        </b-form-group>
        <b-form-group>
          <label for="email">Grand Total :</label>
          <input type="text" name="InvoiceID" class="form-control" v-model="InvoiceGrandTotal" readonly>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Tax :</label>
          <input type="text" name="InvoiceTax" class="form-control" v-model="InvoiceTax" readonly>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">DPP :</label>
          <input type="text" name="InvoiceDPP" class="form-control" v-model="InvoiceDPP" readonly>
        </b-form-group>
    </b-modal>
    <!-- Modal Edit-->
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      centered
      hide-footer
      title="Edit Form"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="editInvoiceProcess"
            >
        <b-form-group>
          <label for="email">Invoice No:</label>
          <input type="text" name="InvoiceNo" class="form-control" v-model="InvoiceNumberOld" readonly>

        </b-form-group>
        <b-form-group>
          <label for="email">Date Pusat:</label>
          <input type="hidden" name="InvoiceID" class="form-control" v-model="InvoiceID">
        <b-form-datepicker
          id="invoice-date"
          v-model="InvoiceDate"
        />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">No Invoice Pusat</label>
          <b-form-input
            type="text"
            placeholder="No Invoice"
          v-model="InvoiceNumber"
          />
        </b-form-group>
        <div class="pull-right">
          <b-btn @click="modalShow = false" class="btn btn-outline-secondary">Cancel</b-btn> &nbsp;
          <b-btn variant="outline-primary"
                type="button" @click="editInvoiceProcess();modalShow=false" class="btn btn-primary">Save</b-btn>
        </div>
      </b-form>
    </b-modal>
        <!-- Modal Upload-->
    <b-modal
      id="modal-upload"
      cancel-variant="outline-secondary"
      v-model="modalUploadShow"
      hide-footer
      centered
      title="Upload Form"
    >

      <b-form>
        <b-form-group>
          <label for="email">File:</label>
          <b-form-file
            placeholder="Choose one or more files or drop it here..."
            drop-placeholder="Drop files here..."
            type="file" id="file" ref="uploadFile" v-on:change="handleFilesUpload"
          />
          <input type="hidden" name="uploadInvoiceId" id="uploadInvoiceId" v-model="uploadInvoiceId">
        </b-form-group>

        <div class="pull-right">
          <b-btn @click="modalUploadShow = false" class="btn btn-outline-secondary">Cancel</b-btn> &nbsp;
          <b-btn variant="outline-primary"
                type="button" @click="uploadAttachment();modalUploadShow=false;" class="btn btn-primary">Upload</b-btn>
        </div>
      </b-form>
    </b-modal>
        <!-- Modal Attachments-->
    <b-modal
      id="modal-attachments"
      cancel-variant="outline-secondary"
      v-model="modalFileAttachments"
      hide-footer
      centered
      title="Download Attachments"
    >
      <b-form>

    <div v-if="fileAttachments">
    <label for="file-list">File List : </label>
    <ul class="list-group">
        <li  class="list-group-item" v-for="item in fileAttachments" :key="item.InvoiceAttachmentID">
              <a target="_blank" @click="downloadAttachment(item.InvoiceAttachment)">{{ item.InvoiceAttachment }}</a> <a style="color:red;" class="float-right" @click="deleteAttachment(item.InvoiceAttachmentID)"><feather-icon icon="TrashIcon" /></a>
        </li>
    </ul>
    </div>
      </b-form>
    </b-modal>
        <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
    <div>
        <div class="messages">
        <ul class="list-group" style="height:300px;width:100%;overflow-y:scroll;" v-if="messagesList.length>0">
          <input type="hidden" name="invoiceMessageId" id="invoiceMessageId" ref="invoiceMessage" v-model="invoiceMessageId">
            <li  class="list-group-item" v-for="item in messagesList" :key="item">
              <div class="float-right" v-if="userFullName.toString()==item.FullName.toString()">
              <h5>Me</h5>
              <span style="font-size:9px">{{dateFormat(item.InputTime)}}</span>
              <p>{{item.invoiceMessage}}</p>
              </div>
              <div class="float-left" v-if="userFullName.toString()!=item.FullName.toString()">
              <h5>{{item.FullName}}</h5>
              <span style="font-size:9px">{{dateFormat(item.InputTime)}}</span>
              <p>{{item.invoiceMessage}}</p>
              </div>
            </li>
        </ul>
    <div v-if="messagesList.length==0">
      No message
      </div><br>
          <form method="post" action="" id="send_message_form">
            <b-row>
            <b-col
          cols="11"
          md="9"
          >
            <input type="text" class="form-control" v-model="messageBox" placeholder="Write your message">
            </b-col>
            <b-col
          cols="11"
          md="3"><input type="button" class="btn btn-primary pull-right" @click="sendMessage()" value="Send"></b-col>
          </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
  <b-tabs content-class="mt-3">
    <b-tab title="All" active>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <b-row>
          <!-- Per Page -->
          <!-- Search -->
          <b-col
            cols="1"
            md="12">
            <vue-json-to-csv
              class = "btn btn-success float-right"
              :csv-title="'invoice_all_local'"
              :json-data = "fetchInvoicesUnsliced"
              :labels = "labels"
              >
              Download CSV
            </vue-json-to-csv>
          </b-col>
        </b-row>
        </div>
      <div class="m-2">
        <b-row>

          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          ><vue-table-dynamic
      ref="refInvoicesListTable"
      :params="fetchInvoices">
           <template v-slot:column-0="{ props }">
           <span>{{ props.row }}</span>
           </template>
           <template v-slot:column-8="{ props }">
              <span class="align-text-top text-capitalize">{{ parseInt(props.cellData.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No' }}
                <feather-icon v-if="parseInt(props.cellData.FlagInvoiceMessage)>0" style="color:#036bfc" icon="MessageCircleIcon" />
              <feather-icon v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0" style="color:#b6ba3c" icon="FileIcon" /></span>
        </template>
           <template v-slot:column-10="{ props }">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              size="sm"
              v-b-modal.modal-detail
              title="Detail"
              @click="showDetail( props.cellData.Reference, `${props.cellData.Currency} ${Number(parseFloat(props.cellData.GrandTotal,10)).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.Tax).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.DPP).toLocaleString()}` )"> <feather-icon icon="SearchIcon" />
              </b-button>
            <b-button
              v-if="props.cellData.DownloadInvoice!=''"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="downloadInvoice( props.cellData.InvoiceID )"> <feather-icon icon="DownloadIcon" />
              </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              v-b-modal.modal-attachments
              v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0"
              title="Show Attachments"
              @click="showAttachments( props.cellData.InvoiceID )"> <feather-icon icon="FileIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              v-b-modal.modal-upload
              title="Upload"
              @click="uploadFiles( props.cellData.InvoiceID )"> <feather-icon icon="UploadIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'"
              size="sm"
              v-b-modal.modal-edit
              title="Edit"
              @click="editInvoice( props.cellData.InvoiceID, dateSimple(props.cellData.Pusat_InvoiceDate), props.cellData.Pusat_NoInvoice, dateSimple(props.cellData.InvoiceDate), props.cellData.InvoiceNumber )"> <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="parseInt(props.cellData.invoiceApproved)==0 && (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')"
              variant="warning"
              size="sm"
              title="Confirm"
              @click="confirmInvoice( props.cellData.InvoiceID )"> <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              v-b-modal.modal-messages
              title="Messages"
              @click="showMessages( props.cellData.InvoiceID )"> <feather-icon icon="MessageCircleIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-if="userRole==='admin' || userRole==='super-admin'"
              size="sm"
              title="Void"
              @click="voidInvoice( props.cellData.InvoiceID )"> <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              title="Contract"
              @click="showContractAttachments( props.cellData.Agreement )"> <feather-icon icon="FileIcon" />
              </b-button>
    </b-button-group>
      </template>
      </vue-table-dynamic>
          </b-col>
        </b-row>
      </div>
        <!-- Table Top -->
    </b-card>
    </b-tab>
    <b-tab title="Confirmed" >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="1"
            md="12">
          <!-- <download-excel class="btn btn-success float-right" :data="fetchInvoicesConfirmedUnsliced" :fields="invoicesAllFields"
  worksheet="Invoices"
  name="invoice_confirmed_local.csv">
          Export Excel
          </download-excel> -->
            <vue-json-to-csv
              class = "btn btn-success float-right"
              :csv-title="'invoice_confirmed_local'"
              :json-data = "fetchInvoicesConfirmedUnsliced"
              :labels = "labels"
              >
              Download CSV
            </vue-json-to-csv>
          </b-col>
        </b-row>
      </div>
       <div class="m-2">
        <b-row>

          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          ><vue-table-dynamic
      ref="refInvoicesListTableConfirmed"
      :params="fetchInvoicesConfirmed">
           <template v-slot:column-0="{ props }">
           <span>{{ props.row }}</span>
           </template>
           <template v-slot:column-8="{ props }">
              <span class="align-text-top text-capitalize">{{ parseInt(props.cellData.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No' }}
                <feather-icon v-if="parseInt(props.cellData.FlagInvoiceMessage)>0" style="color:#036bfc" icon="MessageCircleIcon" />
              <feather-icon v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0" style="color:#b6ba3c" icon="FileIcon" /></span>
        </template>
           <template v-slot:column-10="{ props }">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              size="sm"
              v-b-modal.modal-detail
              title="Detail"
              @click="showDetail( props.cellData.Reference, `${props.cellData.Currency} ${Number(parseFloat(props.cellData.GrandTotal,10)).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.Tax).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.DPP).toLocaleString()}` )"> <feather-icon icon="SearchIcon" />
              </b-button>
            <b-button
              v-if="props.cellData.DownloadInvoice!=''"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="downloadInvoice( props.cellData.InvoiceID )"> <feather-icon icon="DownloadIcon" />
              </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              v-b-modal.modal-attachments
              v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0"
              title="Show Attachments"
              @click="showAttachments( props.cellData.InvoiceID )"> <feather-icon icon="FileIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              v-b-modal.modal-upload
              title="Upload"
              @click="uploadFiles( props.cellData.InvoiceID )"> <feather-icon icon="UploadIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'"
              size="sm"
              v-b-modal.modal-edit
              title="Edit"
              @click="editInvoice( props.cellData.InvoiceID, dateSimple(props.cellData.Pusat_InvoiceDate), props.cellData.Pusat_NoInvoice, dateSimple(props.cellData.InvoiceDate), props.cellData.InvoiceNumber )"> <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="parseInt(props.cellData.invoiceApproved)==0 && (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')"
              variant="warning"
              size="sm"
              title="Confirm"
              @click="confirmInvoice( props.cellData.InvoiceID )"> <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              v-b-modal.modal-messages
              title="Messages"
              @click="showMessages( props.cellData.InvoiceID )"> <feather-icon icon="MessageCircleIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-if="userRole==='admin' || userRole==='super-admin'"
              size="sm"
              title="Void"
              @click="voidInvoice( props.cellData.InvoiceID )"> <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              title="Contract"
              @click="showContractAttachments( props.cellData.Agreement )"> <feather-icon icon="FileIcon" />
              </b-button>
    </b-button-group>
      </template>
      </vue-table-dynamic>
          </b-col>
        </b-row>
      </div>
    </b-card>
    </b-tab>
    <b-tab title="Unconfirmed" >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="1"
            md="12">
          <!-- <download-excel class="btn btn-success float-right" :data="fetchInvoicesUnconfirmedUnsliced" :fields="invoicesAllFields"
  worksheet="Invoices"
  name="invoice_unconfirmed_local.csv">
          Export Excel
          </download-excel> -->
            <vue-json-to-csv
              class = "btn btn-success float-right"
              :csv-title="'invoice_unconfirmed_local'"
              :json-data = "fetchInvoicesUnconfirmedUnsliced"
              :labels = "labels"
              >
              Download CSV
            </vue-json-to-csv>
          </b-col>
        </b-row>

      </div>

       <div class="m-2">
        <b-row>

          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          ><vue-table-dynamic
      ref="refInvoicesListTableUnconfirmed"
      :params="fetchInvoicesUnconfirmed">
           <template v-slot:column-0="{ props }">
           <span>{{ props.row }}</span>
           </template>
           <template v-slot:column-8="{ props }">
              <span class="align-text-top text-capitalize">{{ parseInt(props.cellData.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No' }}
                <feather-icon v-if="parseInt(props.cellData.FlagInvoiceMessage)>0" style="color:#036bfc" icon="MessageCircleIcon" />
              <feather-icon v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0" style="color:#b6ba3c" icon="FileIcon" /></span>
        </template>
           <template v-slot:column-10="{ props }">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              size="sm"
              v-b-modal.modal-detail
              title="Detail"
              @click="showDetail( props.cellData.Reference, `${props.cellData.Currency} ${Number(parseFloat(props.cellData.GrandTotal,10)).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.Tax).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.DPP).toLocaleString()}` )"> <feather-icon icon="SearchIcon" />
              </b-button>
            <b-button
              v-if="props.cellData.DownloadInvoice!=''"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="downloadInvoice( props.cellData.InvoiceID )"> <feather-icon icon="DownloadIcon" />
              </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              v-b-modal.modal-attachments
              v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0"
              title="Show Attachments"
              @click="showAttachments( props.cellData.InvoiceID )"> <feather-icon icon="FileIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              v-b-modal.modal-upload
              title="Upload"
              @click="uploadFiles( props.cellData.InvoiceID )"> <feather-icon icon="UploadIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'"
              size="sm"
              v-b-modal.modal-edit
              title="Edit"
              @click="editInvoice( props.cellData.InvoiceID, dateSimple(props.cellData.Pusat_InvoiceDate), props.cellData.Pusat_NoInvoice, dateSimple(props.cellData.InvoiceDate), props.cellData.InvoiceNumber )"> <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="parseInt(props.cellData.invoiceApproved)==0 && (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')"
              variant="warning"
              size="sm"
              title="Confirm"
              @click="confirmInvoice( props.cellData.InvoiceID )"> <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              v-b-modal.modal-messages
              title="Messages"
              @click="showMessages( props.cellData.InvoiceID )"> <feather-icon icon="MessageCircleIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-if="userRole==='admin' || userRole==='super-admin'"
              size="sm"
              title="Void"
              @click="voidInvoice( props.cellData.InvoiceID )"> <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              title="Contract"
              @click="showContractAttachments( props.cellData.Agreement )"> <feather-icon icon="FileIcon" />
              </b-button>
    </b-button-group>
      </template>
      </vue-table-dynamic>
          </b-col>
        </b-row>
      </div>
    </b-card>
    </b-tab>

    <b-tab title="Empty" >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="1"
            md="12">
          <!-- <download-excel class="btn btn-success float-right" :data="fetchInvoicesEmptyUnsliced" :fields="invoicesAllFields"
  worksheet="Invoices"
  name="invoice_empty_local.csv">
          Export Excel
          </download-excel> -->
            <vue-json-to-csv
              class = "btn btn-success float-right"
              :csv-title="'invoice_empty_local'"
              :json-data = "fetchInvoicesEmptyUnsliced"
              :labels = "labels"
              >
              Download CSV
            </vue-json-to-csv>
          </b-col>
        </b-row>

      </div>

       <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          ><vue-table-dynamic
      ref="refInvoicesListTableEmpty"
      :params="fetchInvoicesEmpty">
           <template v-slot:column-0="{ props }">
           <span>{{ props.row }}</span>
           </template>
           <template v-slot:column-8="{ props }">
              <span class="align-text-top text-capitalize">{{ parseInt(props.cellData.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No' }}
                <feather-icon v-if="parseInt(props.cellData.FlagInvoiceMessage)>0" style="color:#036bfc" icon="MessageCircleIcon" />
              <feather-icon v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0" style="color:#b6ba3c" icon="FileIcon" /></span>
        </template>
           <template v-slot:column-10="{ props }">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              size="sm"
              v-b-modal.modal-detail
              title="Detail"
              @click="showDetail( props.cellData.Reference, `${props.cellData.Currency} ${Number(parseFloat(props.cellData.GrandTotal,10)).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.Tax).toLocaleString()}`, `${props.cellData.Currency} ${Number(props.cellData.DPP).toLocaleString()}` )"> <feather-icon icon="SearchIcon" />
              </b-button>
            <b-button
              v-if="props.cellData.DownloadInvoice!=''"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="downloadInvoice( props.cellData.InvoiceID )"> <feather-icon icon="DownloadIcon" />
              </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              v-b-modal.modal-attachments
              v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0"
              title="Show Attachments"
              @click="showAttachments( props.cellData.InvoiceID )"> <feather-icon icon="FileIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              v-b-modal.modal-upload
              title="Upload"
              @click="uploadFiles( props.cellData.InvoiceID )"> <feather-icon icon="UploadIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'"
              size="sm"
              v-b-modal.modal-edit
              title="Edit"
              @click="editInvoice( props.cellData.InvoiceID, dateSimple(props.cellData.Pusat_InvoiceDate), props.cellData.Pusat_NoInvoice, dateSimple(props.cellData.InvoiceDate), props.cellData.InvoiceNumber )"> <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="parseInt(props.cellData.invoiceApproved)==0 && (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')"
              variant="warning"
              size="sm"
              title="Confirm"
              @click="confirmInvoice( props.cellData.InvoiceID )"> <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              v-b-modal.modal-messages
              title="Messages"
              @click="showMessages( props.cellData.InvoiceID )"> <feather-icon icon="MessageCircleIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-if="userRole==='admin' || userRole==='super-admin'"
              size="sm"
              title="Void"
              @click="voidInvoice( props.cellData.InvoiceID )"> <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              size="sm"
              title="Contract"
              @click="showContractAttachments( props.cellData.Agreement )"> <feather-icon icon="FileIcon" />
              </b-button>
    </b-button-group>
      </template>
      </vue-table-dynamic>
          </b-col>
        </b-row>
      </div>
    </b-card>
    </b-tab>
  </b-tabs>
</div>
    <!-- Table Container Card -->
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTable, BPagination, BButtonGroup, BTab, BTabs,
  BCardHeader, BCardBody, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormDatepicker, BFormFile,
} from 'bootstrap-vue'
import store from '@/store'
import VueJsonToCsv from 'vue-json-to-csv'
import vSelect from 'vue-select'
import moment from 'moment'
import axios from 'axios'
import { onUnmounted } from '@vue/composition-api'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { getToken, getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import useInvoicesList from './useInvoicesList'
import invoicesStoreModule from '../invoicesStoreModule'

const userToken = getToken()
const userRole = ''
const userFullName = getUserData().FullName
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const InvoiceId = ''
const modalUploadShow = false
const modalFileAttachments = false
const uploadInvoiceId = ''
const InvoiceDate = ''
const InvoiceNumber = ''
const InvoiceNumberOld = ''
const modalShow = false
const modalDetailShow = false
const fileAttachments = []
const messagesList = []
const messageBox = ''
const agreementRef = ''
const customer = ''
const description = ''
const startDate_ = ''
const endDate_ = ''
const issuedDate = ''
const attachmentModal = false
const invoiceMessageId = ''
const fetchInvoicesUnsliced = []
const fetchInvoicesConfirmedUnsliced = []
const fetchInvoicesUnconfirmedUnsliced = []
const fetchInvoicesEmptyUnsliced = []
const startDateRaw = new Date()
const endDateRaw = new Date()
const InvoiceReference = ''
const InvoiceGrandTotal = ''
const InvoiceTax = ''
const InvoiceDPP = ''
const fetchInvoicesData = [['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Supp Docs', 'Date Input', 'Actions']]
const fetchInvoicesDataConfirmed = [['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Supp Docs', 'Date Input', 'Actions']]
const fetchInvoicesDataUnconfirmed = [['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Supp Docs', 'Date Input', 'Actions']]
const fetchInvoicesDataEmpty = [['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Supp Docs', 'Date Input', 'Actions']]
startDateRaw.setDate(startDateRaw.getDate() - 30)
export default {
  components: {
    VueJsonToCsv,
    BFormDatepicker,
    BFormFile,
    BCard,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BButtonGroup,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    DateRangePicker,
    BTable,
    BPagination,
    vSelect,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-branch'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, invoicesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
     { label: 'Pending', value: 'pending' },
     { label: 'Active', value: 'active' },
     { label: 'Inactive', value: 'inactive' },
    ]

    const {
      perPageUnconfirmed,
      currentPageUnconfirmed,
      searchQueryUnconfirmed,
      fetchInvoicesUnconfirmed,
      totalInvoicesUnconfirmed,
      refInvoicesListTableUnconfirmed,
      perPageEmpty,
      currentPageEmpty,
      searchQueryEmpty,
      fetchInvoicesEmpty,
      totalInvoicesEmpty,
      refInvoicesListTableEmpty,
      fetchInvoices,
      fetchInvoicesConfirmed,
      tableColumns,
      perPage,
      perPageConfirmed,
      currentPage,
      currentPageConfirmed,
      totalInvoices,
      totalInvoicesConfirmed,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchQueryConfirmed,
      sortBy,
      isSortDirDesc,
      refInvoicesListTableConfirmed,
      refetchDataAll,
      refetchDataAllInvoice,
      refetchDataConfirmedInvoice,
      refetchDataUnconfirmedInvoice,
      refetchDataEmptyInvoice,
      startDate,
      endDate,
      branchId,
      // UI
      resolveInvoicesRoleVariant,
      resolveInvoicesRoleIcon,
      resolveInvoicesStatusVariant,

    } = useInvoicesList()

    return {

      // Sidebar
      branchId,
      perPageConfirmed,
      currentPageConfirmed,
      searchQueryConfirmed,
      fetchInvoicesConfirmed,
      totalInvoicesConfirmed,
      refInvoicesListTableConfirmed,
      perPageUnconfirmed,
      currentPageUnconfirmed,
      searchQueryUnconfirmed,
      fetchInvoicesUnconfirmed,
      totalInvoicesUnconfirmed,
      refInvoicesListTableUnconfirmed,
      perPageEmpty,
      currentPageEmpty,
      searchQueryEmpty,
      fetchInvoicesEmpty,
      totalInvoicesEmpty,
      refInvoicesListTableEmpty,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchDataAll,
      refetchDataAllInvoice,
      refetchDataConfirmedInvoice,
      refetchDataUnconfirmedInvoice,
      refetchDataEmptyInvoice,
      startDate,
      endDate,
      // UI
      resolveInvoicesRoleVariant,
      resolveInvoicesRoleIcon,
      resolveInvoicesStatusVariant,
      statusOptions,
      InvoiceId,
      InvoiceDate,
      InvoiceNumber,
      InvoiceNumberOld,
    }
  },
    watch: {
        branchId: 'getAllInvoices',
        },
  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
      return {
        agreementRef,
        customer,
        description,
        startDate_,
        endDate_,
        issuedDate,
        attachmentModal,
        labels: {
                Customer: { title: 'Customer' },
                JobNo: { title: 'Job No' },
                InvoiceNumber: { title: 'Invoice Number' },
                InvDate: { title: 'Inv Date' },
                Ref: { title: 'Ref' },
                Currency: { title: 'Currency' },
                DPP: { title: 'DPP' },
                Tax: { title: 'Tax' },
                GrandTotal: { title: 'Grand Total' },
                InputPusatDate: { title: 'Input Pusat Date' },
                Pusat_InvoiceDate: { title: 'Inv Date Pusat' },
                Pusat_NoInvoice: { title: 'No Inv Pusat' },
                Confirmed: { title: 'Confirmed' },
                SupportingDoc: { title: 'Supporting Docs' },
        },
        fetchInvoices: {
          data: fetchInvoicesData,
          header: 'row',
          enableSearch: true,
          scrollbar: 'show',
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 310 }, { column: 4, width: 100 }, { column: 3, width: 100 }, { column: 2, width: 100 }, { column: 6, width: 120 }, { column: 7, width: 90 }, { column: 8, width: 120 }, { column: 5, width: 100 }, { column: 9, width: 100 }, { column: 10, width: 360 }],
        },
        fetchInvoicesConfirmed: {
          data: fetchInvoicesDataConfirmed,
          header: 'row',
          enableSearch: true,
          scrollbar: 'show',
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 310 }, { column: 4, width: 100 }, { column: 3, width: 100 }, { column: 2, width: 100 }, { column: 6, width: 120 }, { column: 7, width: 90 }, { column: 8, width: 120 }, { column: 5, width: 100 }, { column: 9, width: 100 }, { column: 10, width: 360 }],
        },
        fetchInvoicesUnconfirmed: {
          data: fetchInvoicesDataUnconfirmed,
          header: 'row',
          enableSearch: true,
          scrollbar: 'show',
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 310 }, { column: 4, width: 100 }, { column: 3, width: 100 }, { column: 2, width: 100 }, { column: 6, width: 120 }, { column: 7, width: 90 }, { column: 8, width: 120 }, { column: 5, width: 100 }, { column: 9, width: 100 }, { column: 10, width: 360 }],
        },
        fetchInvoicesEmpty: {
          data: fetchInvoicesDataEmpty,
          header: 'row',
          enableSearch: true,
          scrollbar: 'show',
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 310 }, { column: 4, width: 100 }, { column: 3, width: 100 }, { column: 2, width: 100 }, { column: 6, width: 120 }, { column: 7, width: 90 }, { column: 8, width: 120 }, { column: 5, width: 100 }, { column: 9, width: 100 }, { column: 10, width: 360 }],
        },
        json_meta: [
          [
           {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
        modalFileAttachments,
        InvoiceReference,
        InvoiceGrandTotal,
        InvoiceTax,
        InvoiceDPP,
        fetchInvoicesUnsliced,
        fetchInvoicesConfirmedUnsliced,
        fetchInvoicesUnconfirmedUnsliced,
        fetchInvoicesEmptyUnsliced,
        userFullName,
        messagesList,
        invoiceMessageId,
        messageBox,
        perfectScrollbarSettings,
        branches: [],
        userRole,
        modalUploadShow,
        uploadInvoiceId,
        file: '',
        fileAttachments,
        modalShow,
        modalDetailShow,
        config: {
              wrap: true,
              dateFormat: 'Y-m-d',
          },
        pickerDates: {
            startDate: this.dataMeta.startDate ? this.dataMeta.startDate : startDateRaw,
            endDate: this.dataMeta.endDate ? this.dataMeta.endDate : endDateRaw,
        },
        locale: {
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          firstDay: 1,
        },
      }
    },
    async mounted() {
      this.userRole = this.$cookies.get('UserRole')
    this.getBranches()
    await this.getAllInvoices()
    await this.getAllInvoicesUnsliced()
    },
  methods: {
    downloadFile(url) {
            window.open(url)
        },
    showContractAttachments(ContractCode) {
      console.log(ContractCode)
        this.currentCode = ContractCode
        console.log('sending')
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
        try {
      axios
        .get(`https://api.geolims.com/invoice_service/getContractAttachmentsRef/${ContractCode}`, { headers: header })
        .then(response => {
          if (response.data.Status === 1) {
        fileAttachments.splice(0, fileAttachments.length)
        const agreementRef_ = response.data.Contract[1]
        const customer_ = response.data.Contract[2]
        const description_ = response.data.Contract[3]
        const issuedDate_ = response.data.Contract[4]
        const startDate__ = response.data.Contract[5]
        const endDate__ = response.data.Contract[6]
        this.agreementRef = agreementRef_
        this.customer = customer_
        this.description = description_
        this.issuedDate = issuedDate_
        this.startDate_ = startDate__
        this.endDate_ = endDate__
        if (response.data.Attachments) {
          response.data.Attachments.map(value => {
            fileAttachments.push(value)
            return value.s3_url
          })
          this.attachmentModal = true
        }
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contract not found',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        } catch (error) {
        console.error('Error fetching contract:', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contract not found',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      },
    async getAllInvoices() {
      await this.emptyArray()
    const userKodeUnitKerja = this.$cookies.get('KodeUnitKerja')
    this.userRole = this.$cookies.get('UserRole')
    const body = {
          DateFrom: moment(this.startDate).format('YYYY-MM-DD'),
          DateTo: moment(this.endDate).format('YYYY-MM-DD'),
      }
      console.log(body)
      console.log('body')
    axios
      .post('https://api.geolims.com/invoice_service/getlocal', body, { headers })
      .then(response => {
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
        const row = []
            row.push('')
            row.push(o.Customer)
            row.push(o.JobNo)
            row.push(o.InvoiceNumber)
            row.push(this.dateFormat(o.InvoiceDate, 'MM/DD/YYYY'))
            row.push(o.Agreement !== null ? o.Agreement : '-')
            row.push(o.Pusat_NoInvoice !== null ? o.Pusat_NoInvoice : '-')
            row.push(o.Pusat_InvoiceDate !== null ? this.dateFormat(o.Pusat_InvoiceDate, 'MM/DD/YYYY') : '-')
            row.push(o)
            row.push(this.dateFormat(o.InputTime))
            row.push(o)
        if (this.userRole === 'branch') {
          if (userKodeUnitKerja.toString() === unitkerja.toString()) {
            if (userKodeUnitKerja.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 3) === 1) {
              fetchInvoicesDataConfirmed.push(row)
            }
            if (userKodeUnitKerja.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 8) === 0 && (o.Pusat_NoInvoice !== null || o.Pusat_NoInvoice !== null)) {
              fetchInvoicesDataUnconfirmed.push(row)
            }
            if (userKodeUnitKerja.toString() === unitkerja.toString() && (o.Pusat_NoInvoice === null || o.Pusat_InvoiceDate === null)) {
             fetchInvoicesDataEmpty.push(row)
            }
          fetchInvoicesData.push(row)
          }
        } else {
        if (this.branchId !== '' && this.branchId !== null) {
          if (this.branchId.toString() === unitkerja.toString()) {
            if (this.branchId.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 3) === 1) {
              fetchInvoicesDataConfirmed.push(row)
            }
            if (this.branchId.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 8) === 0 && (o.Pusat_NoInvoice !== null || o.Pusat_NoInvoice !== null)) {
              fetchInvoicesDataUnconfirmed.push(row)
            }
            if (this.branchId.toString() === unitkerja.toString() && (o.Pusat_NoInvoice === null || o.Pusat_InvoiceDate === null)) {
             fetchInvoicesDataEmpty.push(row)
            }
          fetchInvoicesData.push(row)
          }
        } else {
          if (parseInt(o.invoiceApproved, 3) === 1) {
            fetchInvoicesDataConfirmed.push(row)
          }
          if (parseInt(o.invoiceApproved, 8) === 0 && (o.Pusat_NoInvoice !== null || o.Pusat_NoInvoice !== null)) {
            fetchInvoicesDataUnconfirmed.push(row)
          }
          if (o.Pusat_NoInvoice === null || o.Pusat_InvoiceDate === null) {
            fetchInvoicesDataEmpty.push(row)
          }
        fetchInvoicesData.push(row)
        }
      }
      // console.log(fetchInvoicesData)
    })
    }).catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      },
      async getAllInvoicesUnsliced() {
      const userKodeUnitKerja = this.$cookies.get('KodeUnitKerja')
        console.log('Unsliced adata ready!')
    const body = {
          DateFrom: moment(this.startDate !== '' ? this.startDate : startDateRaw).format('YYYY-MM-DD'),
          DateTo: moment(this.endDate !== '' ? this.endDate : endDateRaw).format('YYYY-MM-DD'),
      }
    axios
      .post('https://api.geolims.com/invoice_service/getlocal', body, { headers })
      .then(response => {
        const all = []
        const confirmed = []
        const unconfirmed = []
        const empty = []
        response.data.Payload.map(o => {
          o.JobNo = o.JobNo.toString()
          const unitkerja = o.InvoiceNumber.toString().substring(0, 3)
          if (this.branchId !== '' && this.branchId !== null) {
            if (this.branchId.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 3) === 1) {
              confirmed.push(o)
              all.push(o)
            } else if (this.branchId.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 3) === 0) {
              unconfirmed.push(o)
              all.push(o)
            }
            if (o.Pusat_NoInvoice === null) {
              empty.push(o)
            }
          } else if (this.userRole === 'branch') {
            if (o.Pusat_NoInvoice === null) {
              empty.push(o)
            }
            if (parseInt(o.invoiceApproved, 3) === 1 && userKodeUnitKerja.toString() === unitkerja.toString()) {
              confirmed.push(o)
              all.push(o)
            } else if (parseInt(o.invoiceApproved, 3) === 0 && userKodeUnitKerja.toString() === unitkerja.toString()) {
              unconfirmed.push(o)
              all.push(o)
            }
            } else {
            if (o.Pusat_NoInvoice === null) {
              empty.push(o)
            }
            if (parseInt(o.invoiceApproved, 3) === 1) {
              confirmed.push(o)
              all.push(o)
            } else if (parseInt(o.invoiceApproved, 3) === 0) {
              unconfirmed.push(o)
              all.push(o)
            }
          }
          return o
        })
        const allReceived = []
        all.map(o => {
          const temp = {
                Customer: o.Customer,
                JobNo: `'${o.JobNo.toString()}`,
                InvoiceNumber: `'${o.InvoiceNumber.toString()}`,
                InvDate: this.dateFormat(o.InvoiceDate, 'MM/DD/YYYY'),
                Ref: o.Reference,
                Currency: o.Currency,
                DPP: o.DPP,
                Tax: o.Tax,
                GrandTotal: o.GrandTotal,
                InputPusatDate: o.Pusat_Timestamp !== null && typeof (o.Pusat_Timestamp) !== 'undefined' ? this.dateFormat(o.Pusat_Timestamp, 'MM/DD/YYYY') : '-',
                Pusat_InvoiceDate: o.Pusat_InvoiceDate !== null && typeof (o.Pusat_InvoiceDate) !== 'undefined' ? this.dateFormat(o.Pusat_InvoiceDate, 'MM/DD/YYYY') : '-',
                Pusat_NoInvoice: o.Pusat_NoInvoice !== null && typeof (o.Pusat_NoInvoice) !== 'undefined' ? o.Pusat_NoInvoice : '-',
                Confirmed: o.invoiceApproved === '1' ? 'Yes' : 'No',
                SupportingDoc: parseInt(o.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No',
            }
            allReceived.push(temp)
        })
        this.fetchInvoicesUnsliced = allReceived
        const comfirmedReceived = []
        confirmed.map(o => {
          const temp = {
                Customer: o.Customer,
                JobNo: `'${o.JobNo.toString()}`,
                InvoiceNumber: `'${o.InvoiceNumber.toString()}`,
                InvDate: this.dateFormat(o.InvoiceDate, 'MM/DD/YYYY'),
                Ref: o.Reference,
                Currency: o.Currency,
                DPP: o.DPP,
                Tax: o.Tax,
                GrandTotal: o.GrandTotal,
                InputPusatDate: o.Pusat_Timestamp !== null && typeof (o.Pusat_Timestamp) !== 'undefined' ? this.dateFormat(o.Pusat_Timestamp, 'MM/DD/YYYY') : '-',
                Pusat_InvoiceDate: o.Pusat_InvoiceDate !== null && typeof (o.Pusat_InvoiceDate) !== 'undefined' ? this.dateFormat(o.Pusat_InvoiceDate, 'MM/DD/YYYY') : '-',
                Pusat_NoInvoice: o.Pusat_NoInvoice !== null && typeof (o.Pusat_NoInvoice) !== 'undefined' ? o.Pusat_NoInvoice : '-',
                Confirmed: o.invoiceApproved === '1' ? 'Yes' : 'No',
                SupportingDoc: parseInt(o.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No',
            }
            comfirmedReceived.push(temp)
        })
          this.fetchInvoicesConfirmedUnsliced = comfirmedReceived
          const unconfirmedReceived = []
        unconfirmed.map(o => {
          const temp = {
                Customer: o.Customer,
                JobNo: `'${o.JobNo.toString()}`,
                InvoiceNumber: `'${o.InvoiceNumber.toString()}`,
                InvDate: this.dateFormat(o.InvoiceDate, 'MM/DD/YYYY'),
                Ref: o.Reference,
                Currency: o.Currency,
                DPP: o.DPP,
                Tax: o.Tax,
                GrandTotal: o.GrandTotal,
                InputPusatDate: o.Pusat_Timestamp !== null && typeof (o.Pusat_Timestamp) !== 'undefined' ? this.dateFormat(o.Pusat_Timestamp, 'MM/DD/YYYY') : '-',
                Pusat_InvoiceDate: o.Pusat_InvoiceDate !== null && typeof (o.Pusat_InvoiceDate) !== 'undefined' ? this.dateFormat(o.Pusat_InvoiceDate, 'MM/DD/YYYY') : '-',
                Pusat_NoInvoice: o.Pusat_NoInvoice !== null && typeof (o.Pusat_NoInvoice) !== 'undefined' ? o.Pusat_NoInvoice : '-',
                Confirmed: o.invoiceApproved === '1' ? 'Yes' : 'No',
                SupportingDoc: parseInt(o.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No',
            }
            unconfirmedReceived.push(temp)
        })
        this.fetchInvoicesUnconfirmedUnsliced = unconfirmedReceived
        const emptyReceived = []
        empty.map(o => {
          const temp = {
                Customer: o.Customer,
                JobNo: `'${o.JobNo.toString()}`,
                InvoiceNumber: `'${o.InvoiceNumber.toString()}`,
                InvDate: this.dateFormat(o.InvoiceDate, 'MM/DD/YYYY'),
                Ref: o.Reference,
                Currency: o.Currency,
                DPP: o.DPP,
                Tax: o.Tax,
                GrandTotal: o.GrandTotal,
                InputPusatDate: o.Pusat_Timestamp !== null && typeof (o.Pusat_Timestamp) !== 'undefined' ? this.dateFormat(o.Pusat_Timestamp, 'MM/DD/YYYY') : '-',
                Pusat_InvoiceDate: o.Pusat_InvoiceDate !== null && typeof (o.Pusat_InvoiceDate) !== 'undefined' ? this.dateFormat(o.Pusat_InvoiceDate, 'MM/DD/YYYY') : '-',
                Pusat_NoInvoice: o.Pusat_NoInvoice !== null && typeof (o.Pusat_NoInvoice) !== 'undefined' ? o.Pusat_NoInvoice : '-',
                Confirmed: o.invoiceApproved === '1' ? 'Yes' : 'No',
                SupportingDoc: parseInt(o.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No',
            }
            emptyReceived.push(temp)
        })
          this.fetchInvoicesEmptyUnsliced = emptyReceived
        console.log('unsliced here!')
        return ''
      })
      .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
      },
      getBranches() {
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => {
                  return { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.KodeUnitKerja }
                })
                this.branches = branchesJoin.sort((a, b) => parseInt(a.value) > parseInt(b.value) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },
    async voidInvoice(invoiceId) {
      const body = {
        InvoiceID: invoiceId,
      }
    axios
      .post('https://api.geolims.com/invoice_service/voidinvoice', body, { headers })
      .then(async response => {
        if (response.data.Status === 1) {
      await this.getAllInvoices()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Void invoice success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    showDetail(reference, grandtotal, tax, dpp) {
      console.log(reference)
      console.log(grandtotal)
      console.log(tax)
      console.log(dpp)
      this.InvoiceReference = reference
      this.InvoiceGrandTotal = grandtotal
      this.InvoiceTax = tax
      this.InvoiceDPP = dpp
    },
    downloadInvoice(id) {
      const url = process.env.VUE_APP_PDF_SERVICE_URL
      const header = {
  'auth-username': process.env.VUE_APP_PDF_SERVICE_USERNAME,
  'auth-password': process.env.VUE_APP_PDF_SERVICE_PASSWORD,
}
      const body = {
        id,
      }
    axios
      .post(url, body, { headers: header })
      .then(response => {
        window.open(response.data.data)
})
    },
    async dateChange(val) {
      this.startDate = val.startDate
      this.endDate = val.endDate
      await this.getAllInvoices()
      await this.getAllInvoicesUnsliced()
    },
    searchChange({ type, target }) {
      console.log(type)
      this.searchQuery = target.value
      this.searchQueryConfirmed = target.value
      this.searchQueryUnconfirmed = target.value
    },
    dateFormat(value, format = 'MM/DD/YYYY hh:mm') {
        return moment(String(value)).format(format)
    },
    dateSimple(value) {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format('YYYY-MM-DD')
        } else { dateRet = null }
        return dateRet
    },
    numberFormat(value) {
      return parseFloat(value).toFixed(2)
    },
    editInvoice(invoiceID, invoiceDatePusat, invoiceNumberPusat, invoiceDate, invoiceNumber) {
      this.InvoiceID = invoiceID
      this.InvoiceDate = invoiceDatePusat !== null ? invoiceDatePusat : invoiceDate
      this.InvoiceNumber = invoiceNumberPusat !== null ? invoiceNumberPusat : ''
      this.InvoiceNumberOld = invoiceNumber
    },
    showMessages(invoiceID) {
      this.invoiceMessageId = invoiceID
      const body = {
        invoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getMessages', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
          console.log('Fetching Data')
      if (response.data.Payload) {
      messagesList.splice(0, messagesList.length)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Message success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        response.data.Payload.map(value => {
          console.log(value.invoiceMessage)
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    async sendMessage() {
      const invoiceID = this.invoiceMessageId
      const Message = this.messageBox
      const body = {
        invoiceID,
        Message,
      }
    axios
      .post('https://api.geolims.com/invoice_service/inputMessages', body, { headers })
      .then(async response => {
      await this.getAllInvoices()
        if (response.data.Status === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sending Message success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.messageBox = ''
      const body2 = {
        invoiceID: this.invoiceMessageId,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getMessages', body2, { headers })
      .then(response2 => {
        if (response2.data.Status === 1) {
          console.log('Fetching Data')
      if (response2.data.Payload) {
      messagesList.splice(0, messagesList.length)
        response2.data.Payload.map(value => {
          console.log(value.invoiceMessage)
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        }
        })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    async deleteAttachment(attachmentID) {
      const body = {
        InvoiceAttachmentID: attachmentID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/deleteAttachment', body, { headers })
      .then(async response => {
      await this.getAllInvoices()
        if (response.data.Status === 1) {
          this.modalFileAttachments = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    showAttachments(invoiceID) {
      const body = {
        invoiceMasterID: invoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getAttachments', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
      fileAttachments.splice(0, fileAttachments.length)
      if (response.data.Payload) {
        response.data.Payload.map(value => {
          fileAttachments.push(value)
          return value.InvoiceAttachment
        })
      }
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    async uploadAttachment() {
      console.log('sending')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        'X-Token-Access': `Bearer ${userToken}`,
      }
      const formData = new FormData()
      formData.append('file', this.file[0], this.file[0].name)
      console.log(formData)
    axios
      .post(`https://api.geolims.com/invoice_service/upload-docs?invoiceid=${this.uploadInvoiceId}`, formData, { headers: header })
      .then(async response => {
        if (response.data.Status === 1) {
      await this.getAllInvoices()
          this.modalUploadShow = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Upload Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    async editInvoiceProcess() {
      const body = {
        InvoiceID: this.InvoiceID,
        Date: this.InvoiceDate,
        NoInvoice: this.InvoiceNumber,
      }
    axios
      .post('https://api.geolims.com/invoice_service/saveInvoicePusat', body, { headers })
      .then(async response => {
      await this.getAllInvoices()
        if (response.data.Status === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edit Invoice success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
      handleFilesUpload(e) {
        this.file = e.target.files || e.dataTransfer.files
      },
      uploadFiles(invoiceID) {
        this.uploadInvoiceId = invoiceID
      },
     async confirmInvoice(invoiceID) {
      const body = {
        InvoiceID: invoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/ApproveInvoice', body, { headers })
      .then(async response => {
        if (response.data.Status === 1) {
      await this.getAllInvoices()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Invoice success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
     },
     urlToBlob(url) {
      const contentType = this.getBlobType(url)
      const header = {
        'X-Token-Access': `Bearer ${userToken}`,
        'Content-Type': contentType,
      }
          axios
            .get(url, { headers: header, responseType: 'blob' })
            .then(response => {
          const file = new Blob([response.data], { type: contentType })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
            })
     },
     getBlobType(url) {
        const extension = url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
        let result = ''
        if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif' || extension === 'bmp') {
        result = `image/${extension}`
        } else if (extension === 'pdf') {
        result = `application/${extension}`
        } else if (extension === 'txt') {
        result = 'text/plain'
        }
        return result
     },
      getNum(val) {
        if (Number.isNaN(val)) {
          return 0
        }
        return val
      },
      async emptyArray() {
        const header = ['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Supp Docs', 'Date Input', 'Actions']
        fetchInvoicesData.length = 0
        fetchInvoicesDataConfirmed.length = 0
        fetchInvoicesDataUnconfirmed.length = 0
        fetchInvoicesDataEmpty.length = 0
        while (fetchInvoicesData.length > 0) {
          fetchInvoicesData.pop()
        }
        while (fetchInvoicesDataConfirmed.length > 0) {
          fetchInvoicesDataConfirmed.pop()
        }
        while (fetchInvoicesDataUnconfirmed.length > 0) {
          fetchInvoicesDataUnconfirmed.pop()
        }
        while (fetchInvoicesDataEmpty.length > 0) {
          fetchInvoicesDataEmpty.pop()
        }
        fetchInvoicesData.push(header)
        fetchInvoicesDataConfirmed.push(header)
        fetchInvoicesDataUnconfirmed.push(header)
        fetchInvoicesDataEmpty.push(header)
      },
      async downloadAttachment(fileName) {
        const url = `https://api.geolims.com/asset_service/saved-assets/`
        const folderName = `InvoiceAttachment/`
        const FileName = fileName.replace(url, '')
        const ObjectName = `${folderName}${FileName}`
        const bodyFormData = new FormData()
        bodyFormData.append('ObjectName', ObjectName)
        bodyFormData.append('FileName', FileName)
      axios
        .post(`https://api.geolims.com/asset_service/get_asset`, bodyFormData, { headers })
        .then(response => {
        if (response.data.Status === 1) {
          window.open(response.data.Payload, '_blank')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Download Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
